import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { faBan, faGrip } from "@fortawesome/free-solid-svg-icons";
import { AxiosError } from "axios";
import { Loader, PageState } from "components";
import { sendTransactions } from "@multiversx/sdk-dapp/services/transactions/sendTransactions";
import { refreshAccount } from "@multiversx/sdk-dapp/utils/account/refreshAccount";
import { MyApiNetworkProvider } from "helpers/MyApiNetworkProvider";
import {
	TokenPayment,
	ESDTTransferPayloadBuilder,
	Address,
} from "@multiversx/sdk-core";
import { ExplorerLink, FormatAmount } from "@multiversx/sdk-dapp/UI";
import {
	useGetAccount,
	useGetActiveTransactionsStatus,
	useGetNetworkConfig,
} from "hooks";
import {
	ServerTransactionType,
	NonFungibleToken,
	TokenDetailsFromNetwork,
} from "types";
import CountUp from "react-countup";
import { string2hex } from "helpers";
import BigNumber from "bignumber.js";

import { rewardToken } from "config";
import { DepositRewards } from "components/Admin/DepositRewards";

type TokenDetails = {
	ticker: string;
	lockedTicker: string | undefined;
	token?: TokenDetailsFromNetwork;
	lockedToken?: TokenDetailsFromNetwork;
};

export const TokenDetails = () => {
	const {
		network: { apiAddress },
	} = useGetNetworkConfig();
	const apiNetworkProvider = new MyApiNetworkProvider(apiAddress);

	const { tokenId } = useParams<{ tokenId: string }>();

	const [tokenDetails, setTokenDetails] = useState<TokenDetails>({
		ticker: tokenId || "",
		lockedTicker: "LKDEFRA-84c10d",
	});

	useEffect(() => {
		if (tokenId) {
			apiNetworkProvider
				.getTokenDetailsFromNetwork(tokenId)
				.then((token) => {
					setTokenDetails((prev) => ({ ...prev, token }));
				});
		}

		if (tokenDetails.lockedTicker) {
			apiNetworkProvider
				.getTokenDetailsFromNetwork(tokenDetails.lockedTicker)
				.then((lockedToken) => {
					setTokenDetails((prev) => ({ ...prev, lockedToken }));
				});
		}
	}, []);

	useEffect(() => {
		console.log("tokenDetails", tokenDetails.token);
		console.log("lockedToken", tokenDetails.lockedToken);
	}, [tokenDetails]);

	return (
		<div>
			<div className="container">
				<h1>Token Details</h1>

				<div className="bg-secondary p-4 text-white">
					<h4>
						Ticker: {tokenDetails.ticker}
						<ExplorerLink page={"/tokens/" + tokenDetails.ticker} />
					</h4>
					{tokenDetails.lockedTicker && (
						<h4>
							Locked Ticker: {tokenDetails.lockedTicker}
							<ExplorerLink
								page={"/tokens/" + tokenDetails.lockedTicker}
							/>
						</h4>
					)}
				</div>

				<div className="bg-secondary mt-3 p-4 text-white">
					{tokenDetails.token && tokenDetails.lockedToken && (
						<h4>
							Locked amount:&nbsp;
							<FormatAmount
								value={new BigNumber(
									tokenDetails.lockedToken.minted
								)
									.minus(
										new BigNumber(
											tokenDetails.lockedToken.burnt
										)
									)
									.toString(10)}
								token={tokenDetails.token.ticker}
								decimals={tokenDetails.lockedToken.decimals}
							/>
						</h4>
					)}
				</div>
			</div>
		</div>
	);
};
