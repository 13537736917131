import React, { useEffect, useState } from "react";
import { faBan, faGrip } from "@fortawesome/free-solid-svg-icons";
import { AxiosError } from "axios";
import { Loader, PageState } from "components";
import { sendTransactions } from "@multiversx/sdk-dapp/services/transactions/sendTransactions";
import { refreshAccount } from "@multiversx/sdk-dapp/utils/account/refreshAccount";
import { MyApiNetworkProvider } from "helpers/MyApiNetworkProvider";
import {
	TokenPayment,
	ESDTTransferPayloadBuilder,
	Address,
} from "@multiversx/sdk-core";
import {
	useGetAccount,
	useGetActiveTransactionsStatus,
	useGetNetworkConfig,
} from "hooks";
import { ServerTransactionType, NonFungibleToken } from "types";
import CountUp from "react-countup";
import { string2hex } from "helpers";
import BigNumber from "bignumber.js";

import { rewardToken } from "config";
import { DepositRewards } from "components/Admin/DepositRewards";

export const AdminSettings = () => {
	return (
		<div>
			<div className="container">
				<div className="bg-secondary p-4 mt-4">Admin</div>
			</div>
		</div>
	);
};
