import React from "react";
import {
	faChartSimple,
	faFileSignature,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navbar as BsNavbar, NavItem, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { dAppName, adminAddresses } from "config";
import { logout } from "helpers";
import { useGetIsLoggedIn, useGetAccount } from "hooks";
import { routeNames } from "routes";
import { ReactComponent as MultiversXLogo } from "../../assets/img/multiversx.svg";
import LogoTortuga from "../../assets/img/logo.png";
import { Footer } from "./Footer";

export const Sidebar = () => {
	const isLoggedIn = useGetIsLoggedIn();
	const { address } = useGetAccount();

	const navbarExpand = "md";

	const handleLogout = () => {
		logout(`${window.location.origin}/unlock`);
	};

	return (
		<BsNavbar
			expand={navbarExpand}
			className="sidebar border-bottom px-4 py-3"
		>
			<BsNavbar.Brand>
				<Link
					className="d-flex align-items-center navbar-brand mr-0"
					to={isLoggedIn ? routeNames.dashboard : routeNames.home}
				>
					<img
						src={LogoTortuga}
						alt="Tortuga Staking"
						className="tortuga-logo"
					/>
					<span className="dapp-name text-white">{dAppName}</span>
				</Link>
			</BsNavbar.Brand>

			<BsNavbar.Toggle aria-controls="responsive-navbar-nav" />

			<BsNavbar.Collapse id="responsive-navbar-nav">
				<Nav className="ml-auto d-flex flex-column">
					<NavItem>
						<Link
							to={routeNames.adminSettings}
							className={
								"btn btn-primary mb-2 mb-" +
								navbarExpand +
								"-0 mr-0 mr-" +
								navbarExpand +
								"-2"
							}
						>
							Admin Settings
						</Link>
					</NavItem>
					{isLoggedIn && (
						<>
							{adminAddresses.includes(address) && (
								<NavItem>
									<Link
										to={routeNames.adminSettings}
										className={
											"btn btn-primary mb-2 mb-" +
											navbarExpand +
											"-0 mr-0 mr-" +
											navbarExpand +
											"-2"
										}
									>
										Admin Settings
									</Link>
								</NavItem>
							)}
							<NavItem>
								<button
									className="btn btn-primary"
									onClick={handleLogout}
								>
									Logout
								</button>
							</NavItem>
						</>
					)}
					<Footer />
				</Nav>
			</BsNavbar.Collapse>
		</BsNavbar>
	);
};

/*
<div className="sidebar">
				<a href="#">Link 1</a>
				<a href="#">Link 2</a>
				<a href="#">Link 3</a>
				<a href="#">Link 4</a>
				<a href="#">Link 5</a>
				<a href="#">Link 6</a>
				<a href="#">Link 7</a>
				<a href="#">Link 8</a>
				<a href="#">Link 9</a>
				<a href="#">Link 10</a>
				<Footer />
			</div>
			*/
