import React from "react";
import { useLocation } from "react-router-dom";
import { AuthenticatedRoutesWrapper } from "components";
import { routes, routeNames } from "routes";
import { Footer } from "./Footer";
import { Sidebar } from "./Sidebar";

export const Layout = ({ children }: { children: React.ReactNode }) => {
	const { search } = useLocation();
	return (
		<div className="bg-light d-flex flex-row flex-fill wrapper">
			<Sidebar />
			<main className="d-flex flex-column flex-grow-1 mt-4">
				<AuthenticatedRoutesWrapper
					routes={routes}
					unlockRoute={`${routeNames.unlock}${search}`}
				>
					{children}
				</AuthenticatedRoutesWrapper>
			</main>
		</div>
	);
};

/*

      <Navbar />
      */
